import React from 'react'
import { Link } from 'gatsby'
import { Seo } from "../components/seo/default"
import { graphql } from "gatsby"
import { Layout } from "../components/layout/layout"
import ArticleImage from '../components/utils/ArticleImage'
import Pagination from '../components/utils/Pagination'
import { STRINGS } from "../constants"
import { BLOG_DESCRIPTION } from '../constants/strings'

const BLOG_TITLE = STRINGS.BLOG_TITLE
const DESCRIPTION = STRINGS.BLOG_DESCRIPTION
const IMAGE = {
    "altText": "10 Gift Ideas For The Piercing Fanatic In Your Life",
    "height": 702,
    "id": "gid://shopify/ArticleImage/43614240827",
    "url": "https://cdn.shopify.com/s/files/1/0131/2368/2363/articles/10_Gift_Ideas_For_The_Piercing_Fanatic_In_Your_Life.jpg?v=1608564620",
    "width": 1000
}

export default function BlogsPage({
    data: { articles },
    pageContext: {
        currentPage
    }
}) {
    return (
        <Layout>
            <Seo title={`${BLOG_TITLE}`} />
            <div className='mx-auto max-w-screen-xl'>
                <div className="page-header py-4">
                    <h1 className="text-xl text-center font-bold pb-1">{BLOG_TITLE}</h1>
                    {
                        BLOG_DESCRIPTION !== '' && (
                            <div className="px-2">
                                <div className="text-center">
                                    <div className={`text-sm`}>{DESCRIPTION}</div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="block sm:grid sm:grid-cols-2 grid-cols-blog sm:gap-3">
                {
                    articles?.nodes.map((article, index) => (
                        <div className="py-2 px-4 mb-4" key={index}>
                            <div className="py-2">
                                <ArticleImage
                                    image={process.env.NODE_ENV === 'development' ? IMAGE : article.image}
                                    layout="fullWidth"
                                    formats={["jpg","webp"]}
                                    width={400}
                                    alt={article.title}
                                    placeholder="BLURRED"
                                    loading={"lazy"}
                                    objectFit="contain"
                                />
                            </div>
                            <div className="article-content py-2">
                                <h3 className="text-sm font-semibold py-2">{article.title}</h3>
                                <div className={`text-sm pb-2 product-description`}>{article.excerpt}</div>
                            </div>
                            <div className="article-buttons py-2">
                                <Link to={`/blogs/magazine/${article.handle}`} className="px-4 py-2 bg-primary text-white mr-2 text-sm font-semibold">Read more</Link>
                                <Link to={`/body-piercing-jewellery`} className="px-4 py-2 bg-gray-sm text-black text-sm font-semibold">Shop Now</Link>
                            </div>
                        </div>
                    ))
                }
                </div>
                <div className="py-8 px-4">
                    <Pagination
                        currentPage={currentPage}
                        pageInfo={articles?.pageInfo}
                    />
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    articles: allShopifyArticle(limit: $limit, sort: {fields: publishedAt, order: DESC}, skip: $skip) {
        nodes {
            id
            title
            handle
            image {
                altText
                height
                id
                url
                width
            }
            contentHtml
            excerpt
        }
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
    }
  }
`
